<template>
  <am-lead-success-view v-if="showSuccessView" :model-code="this.modelCode" />
  <am-two-columns-sticky-layout v-else class="am-mcv">
    <template #left>
      <am-vehicle-model-preview
        v-if="modelWltpMeasurements.length"
        :title="modelTitle"
        :image-sources="modelImageSources"
        :image-fallback-source="modelImageFallbackSource"
        :image-alt="modelImageAlt"
        :model-wltp-measurements="modelWltpMeasurements"
        :model-hybrid-type="modelHybridType"
        class="am-mcv__model-preview"
      />
    </template>
    <template #right>
      <amui-headline
        text="Jetzt individuelles Angebot erhalten."
        html-tag="h1"
        looks-like="h5"
      />
      <form autocomplete="on">
        <am-vehicle-model-selection-form
          ref="modelSelectionForm"
          :series.sync="series"
          :series-options="seriesOptions"
          :model-range.sync="modelRange"
          :model-range-options="modelRangeOptions"
          :model-code.sync="modelCode"
          :model-code-options="modelCodeOptions"
          class="am-mcv__model-selection"
        />

        <div class="am-mcv__vehicle-mobile-slot">
          <am-vehicle-model-preview
            v-if="modelWltpMeasurements.length"
            :title="modelTitle"
            :image-sources="modelImageSources"
            :image-fallback-source="modelImageFallbackSource"
            :image-alt="modelImageAlt"
            :model-wltp-measurements="modelWltpMeasurements"
            :model-hybrid-type="modelHybridType"
            class="am-mcv__model-preview"
          />
        </div>

        <hr class="am-mcv__separator" />

        <am-dealer-location-form
          ref="dealerLocationForm"
          title="Wählen Sie eine Filiale in Ihrer Nähe aus."
          :dealer-id.sync="dealerId"
          :dealers="dealers"
        />

        <am-personal-data-form
          ref="personalDataForm"
          :salutation.sync="salutation"
          :first-name.sync="firstName"
          :last-name.sync="lastName"
          :birthdate.sync="birthdate"
          :zip-code.sync="zipCode"
          :email.sync="email"
          :phone.sync="phone"
          class="am-mcv__personal-data"
        />

        <am-planned-purchase-form
          ref="plannedPurchaseForm"
          :period.sync="period"
          :period-options="periodOptions"
          class="am-mcv__planned-purchase"
        />

        <am-legal-confirmations-form
          ref="legalConfirmationsForm"
          :has-confirmed-contact.sync="hasConfirmedContact"
          :has-confirmed-terms.sync="hasConfirmedTerms"
          :terms-and-conditions-url="termsAndConditionsUrl"
          :privacy-policy-url="privacyPolicyUrl"
          class="am-mcv__legal-confirmations"
        />

        <amui-button
          label="Angebot anfordern"
          icon-right="arrow-slide-right"
          :disabled="isSubmitting"
          @click.prevent="onSubmit"
        />
      </form>

      <hr class="am-tdv__separator" v-if="dealerId" />

      <am-dealer-information
        v-if="dealerId"
        :dealer-id="dealerId"
        small-headline
      />
    </template>
  </am-two-columns-sticky-layout>
</template>

<script>
import { AmuiButton } from '@/../ui/components/button'
import { AmuiHeadline } from '@/../ui/components/headline'
import AmTwoColumnsStickyLayout from '@/app/layouts/two-columns-sticky-layout/two-columns-sticky-layout.vue'
import AmVehicleModelPreview from '@/app/components/vehicle-model-preview/vehicle-model-preview.vue'
import AmVehicleModelSelectionForm from '@/app/components/form-partials/vehicle-model-selection/vehicle-model-selection.vue'
import AmPersonalDataForm from '@/app/components/form-partials/personal-data/personal-data.vue'
import AmPlannedPurchaseForm from '@/app/components/form-partials/planned-purchase/planned-purchase.vue'
import AmLegalConfirmationsForm from '@/app/components/form-partials/legal-confirmations/legal-confirmations.vue'
import AmDealerLocationForm from '@/app/components/form-partials/dealer-location/dealer-location.vue'
import AmDealerInformation from '@/app/components/dealer-information/dealer-information.vue'
import AmLeadSuccessView from '@/app/views/lead-success/lead-success.vue'
import { genHash } from '@/../utils/crypto'
import { mapGetters, mapState, mapActions } from 'vuex'
import axios from 'axios'
import { getGTMTracker } from '@/app/tracking/gtm.js'

export default {
  name: 'TestDriveView',

  components: {
    AmuiButton,
    AmuiHeadline,
    AmTwoColumnsStickyLayout,
    AmVehicleModelPreview,
    AmVehicleModelSelectionForm,
    AmPersonalDataForm,
    AmPlannedPurchaseForm,
    AmLegalConfirmationsForm,
    AmDealerLocationForm,
    AmDealerInformation,
    AmLeadSuccessView
  },

  data: () => {
    return {
      showSuccessView: false,
      rawModelData: null,
      rawModelWltpData: null,
      selectedSeries: null,
      selectedModelRange: null,
      selectedModelCode: null,
      salutation: null,
      firstName: '',
      lastName: '',
      birthdate: '',
      zipCode: '',
      email: '',
      phone: '',
      period: null,
      periodOptions: [
        {
          value: '3-6',
          label: 'innerhalb von 3 bis 6 Monaten'
        },
        {
          value: '7-12',
          label: 'innerhalb von 7 bis 12 Monaten'
        },
        {
          value: '13-24',
          label: 'innerhalb von 13 bis 24 Monaten'
        },
        {
          value: '-',
          label: 'keine Pläne'
        }
      ],
      hasConfirmedContact: false,
      hasConfirmedTerms: false,
      dealerId: null,
      isSubmitting: false
    }
  },

  computed: {
    ...mapState('core', {
      rawVehicleMarketCompanyData: 'rawVehicleMarketCompanyData',
      rawCompanyDealerData: 'rawCompanyDealerData'
    }),
    ...mapGetters('tracking', ['firstUtmTouchpoint', 'lastUtmTouchpoint']),
    dealers() {
      return this.rawCompanyDealerData.map(d => ({
        value: d.source.key,
        label: d.name,
        coordinates: {
          latitude: d.coordinates.lat,
          longitude: d.coordinates.lon
        }
      }))
    },
    termsAndConditionsUrl() {
      let url = null

      if (this.rawVehicleMarketCompanyData?.termsAndConditionsLink) {
        url = this.rawVehicleMarketCompanyData.termsAndConditionsLink
      }

      return url
    },
    privacyPolicyUrl() {
      let url = null

      if (this.rawVehicleMarketCompanyData?.privacyPolicyLink) {
        url = this.rawVehicleMarketCompanyData.privacyPolicyLink
      }

      return url
    },
    seriesOptions() {
      let options = []
      const series = new Set()

      if (this.rawModelData) {
        this.rawModelData
          .filter(d => d.brand === this.brand)
          .forEach(d => {
            series.add(d.series)
          })

        options = [...series].toSorted().map(serie => {
          const label = serie + (isNaN(serie) ? '' : 'er')
          return {
            label: label,
            value: serie
          }
        })
      }

      return options
    },
    modelRangeOptions() {
      let options = []
      const modelRanges = new Map()

      if (this.rawModelData) {
        this.rawModelData
          .filter(d => d.brand === this.brand && d.series === this.series)
          .toSorted((a, b) => (a.modelRangeLabel > b.modelRangeLabel ? 1 : -1))
          .forEach(d => {
            modelRanges.set(d.modelRange, d.modelRangeLabel)
          })

        options = [...modelRanges].map(d => {
          return {
            label: d[1],
            value: d[0]
          }
        })
      }

      return options
    },

    modelTitle() {
      return (
        this.rawModelData?.find(d => d.modelCode === this.modelCode)?.model ??
        null
      )
    },

    modelImageSources() {
      let sources = []

      if (this.modelCode) {
        sources = [
          {
            media: '(min-width: 900px)',
            srcset: [
              this.getImageSource(900),
              this.getImageSource(1800) + ' 2x'
            ].join(', ')
          },
          {
            media: '(min-width: 768px)',
            srcset: [
              this.getImageSource(768),
              this.getImageSource(1536) + ' 2x'
            ].join(', ')
          },
          {
            media: '(min-width: 600px)',
            srcset: [
              this.getImageSource(600),
              this.getImageSource(1200) + ' 2x'
            ].join(', ')
          },
          {
            media: 'all',
            srcset: [
              this.getImageSource(480),
              this.getImageSource(960) + ' 2x'
            ].join(', ')
          }
        ]
      }

      return sources
    },

    modelImageFallbackSource() {
      return this.modelImageSources.length
        ? this.modelImageSources[0].srcset
        : null
    },

    modelImageAlt() {
      return this.modelTitle
    },

    modelCodeOptions() {
      let options = []
      const modelCodes = new Map()

      if (this.rawModelData) {
        this.rawModelData
          .filter(
            d =>
              d.brand === this.brand &&
              d.series === this.series &&
              d.modelRange === this.modelRange
          )
          .toSorted((a, b) => (a.model > b.model ? 1 : -1))
          .forEach(d => {
            modelCodes.set(d.modelCode, d.model)
          })

        options = [...modelCodes].map(d => {
          return {
            label: d[1],
            value: d[0]
          }
        })
      }

      return options
    },

    brand() {
      let brand = null

      if (this.rawModelData) {
        const found = this.rawModelData.find(
          d => d.brand.toLowerCase() === this.$route.params.brand?.toLowerCase()
        )
        if (found) {
          brand = found.brand
        }
      }

      return brand
    },

    currentModelData() {
      let data = null

      // TODO: check that case again, why infinite loop when using modelCode property
      if (this.$route.query.model && this.rawModelData) {
        const found = this.rawModelData.find(
          d => d.brand === this.brand && d.modelCode === this.$route.query.model
        )

        if (found) {
          data = found
        }
      }

      return data
    },

    series: {
      get() {
        let series = this.selectedSeries

        if (series === null) {
          if (this.currentModelData) {
            series = this.currentModelData.series
          }
        }

        return series
      },
      set(value) {
        this.selectedSeries = value
      }
    },

    modelRange: {
      get() {
        let modelRange = this.selectedModelRange

        if (modelRange === null) {
          if (this.currentModelData) {
            modelRange = this.currentModelData.modelRange
          }
        }

        return modelRange
      },
      set(value) {
        this.selectedModelRange = value
      }
    },

    modelCode: {
      get() {
        let modelCode = this.selectedModelCode

        if (modelCode === null) {
          if (this.currentModelData) {
            modelCode = this.currentModelData.modelCode
          }
        }

        return modelCode
      },
      set(value) {
        this.selectedModelCode = value
      }
    },

    wmi() {
      let wmi = null

      if (this.rawModelData && this.modelCode !== null) {
        const found = this.rawModelData.find(
          d => d.modelCode === this.modelCode
        )

        if (found) {
          wmi = found.wmi
        }
      }

      return wmi
    },

    modelWltpMeasurements() {
      let data = []

      if (this.rawModelWltpData) {
        data = this.rawModelWltpData
          .filter(d => d.modelCode === this.modelCode && d.brand === this.brand)
          .map(d => ({
            measurement: d.measurement,
            unit: d.unit,
            min: d.min,
            max: d.max
          }))
      }

      return data
    },

    modelHybridType() {
      let type = null

      if (this.rawModelWltpData) {
        const found = this.rawModelWltpData.find(
          d => d.modelCode === this.modelCode && d.brand === this.brand
        )

        if (found) {
          type = found.hybridType
        }
      }

      return type
    }
  },

  created() {
    this.fetchModelData()
    this.fetchModelWltpData()
  },

  watch: {
    modelCode(modelCode) {
      if (this.$route.query.model !== modelCode) {
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, model: modelCode }
        })
      }
    }
  },

  mounted() {
    this.gtm = getGTMTracker()

    this.gtm.trackEvent({
      name: 'model_configurator_page_view'
    })
  },

  methods: {
    ...mapActions('core', ['addNotification']),
    getImageSource(width) {
      return `https://office.carpresenter.de/cosyapi.jsp?cut=3&width=${width}&vin=${this.wmi}${this.modelCode}0000000000&format=png&token=afiAunLnvI89EtuNiofiu9i3ocdmsdJHH`
    },
    fetchModelData() {
      axios
        .get('https://workflow.automedia.de/webhook/model-data')
        .then(async response => {
          if (response.data.length === 1) {
            this.rawModelData = response.data[0].json_agg ?? []
          }
        })
        .catch(error => {
          console.error('error due fetching model data', error)
        })
    },
    fetchModelWltpData() {
      axios
        .get('https://workflow.automedia.de/webhook/model-wltp-data')
        .then(async response => {
          if (response.data.length === 1) {
            this.rawModelWltpData = response.data[0].json_agg ?? []
          }
        })
        .catch(error => {
          console.error('error due fetching model wltp range data', error)
        })
    },
    async onSubmit() {
      const modelSelectionRes = this.$refs.modelSelectionForm.validate()
      const personalDataRes = this.$refs.personalDataForm.validate()
      const plannedPurchaseRes = this.$refs.plannedPurchaseForm.validate()
      const legalConfirmationsRes = this.$refs.legalConfirmationsForm.validate()

      const isValid = [
        modelSelectionRes,
        personalDataRes,
        plannedPurchaseRes,
        legalConfirmationsRes
      ].every(d => d.valid)

      if (isValid) {
        this.isSubmitting = true

        const dealer = this.rawCompanyDealerData.find(
          d => d.source.key === this.dealerId
        )

        const origin = {}

        if (this.lastUtmTouchpoint.source !== null) {
          origin.source = this.lastUtmTouchpoint.source
        }

        if (this.lastUtmTouchpoint.medium !== null) {
          origin.source = this.lastUtmTouchpoint.medium
        }

        if (this.lastUtmTouchpoint.campaign !== null) {
          origin.source = this.lastUtmTouchpoint.campaign
        }

        const payload = {
          version: '1.0.0',
          client: {
            first_name: this.firstName,
            last_name: this.lastName,
            postal_code: this.zipCode,
            phone: this.phone,
            email: this.email,
            custom: {
              salutation: this.salutation,
              birthdate: this.birthdate ?? null
            }
          },
          sales_lead: {
            car: {
              type: 'new',
              category: 'car',
              make: this.brand,
              model: this.modelTitle,
              url: window.location.origin + this.$route.fullPath
            },
            type: 'REQUEST_FOR_OFFER',
            dealer_location:
              dealer.name +
              (dealer.address
                ? ' | ' +
                  dealer.address.street +
                  ' ' +
                  dealer.address.houseNumber +
                  ', ' +
                  dealer.address.zipcode +
                  ' ' +
                  dealer.address.city
                : ''),
            custom: {
              model: {
                series: this.series,
                model_range: this.modelRange,
                model_code: this.modelCode
              },
              source: {
                utm: {
                  first_touchpoint: this.firstUtmTouchpoint,
                  last_touchpoint: this.lastUtmTouchpoint
                }
              },
              meta: {
                planned_purchase_period_in_months: this.period,
                has_confirmed_contact: this.hasConfirmedContact,
                has_confirmed_terms: this.hasConfirmedTerms
              },
              dealer: {
                id: this.dealerId,
                company_id: dealer._company
              }
            }
          }
        }

        if (origin.length) {
          payload.sales_lead.origin = origin
        }

        axios
          .post('https://workflow.automedia.de/webhook/leads', payload, {
            headers: {
              hash: genHash({
                input: payload,
                config: {
                  property: 'client.email',
                  key: 'JzdWIiOiIxMjM0NTY3ODk'
                }
              })
            }
          })
          .then(() => {
            this.gtm.trackEvent({
              name: 'model_configurator_page_form_submitted',
              payload
            })

            this.showSuccessView = true
          })
          .catch(error => {
            console.error('error due sending lead', error)
            this.addNotification({
              message: 'Ihre Anfrage konnte leider nicht verarbeitet werden.',
              type: 'error'
            })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    }
  }
}
</script>
